require('core-js/stable');
require('regenerator-runtime/runtime');

const { getParams } = require('@utils/helpers');
const { v1: uuid } = require('uuid');

class ComponentPrints {
  /**
   * Send prints
   * @param {object} component
   * @param {number} componentIndex
   * @returns {void}
   */
  static sendPrint(component, analytics, webView, sendContainersFlag) {
    if (typeof melidata === 'function') {
      const { component_name, data, items, variations } = component;
      const componentItems = ['CarouselDynamic', 'Grid', 'Carousel', 'MainSlider', 'CategoryList'];
      const excludeComponents = ['Tracking', 'Disclaimer'];
      let eventData = {};

      if (items.length > 0) {
        items.map((item, index) => {
          const isCIDItemOrCategoryItem = !!item?.componentName;

          if (isCIDItemOrCategoryItem) {
            const link = data?.viewMoreLink || item.data?.permalink;
            const label = data.title;
            const categoryId = item.data?.categoryId;
            const source = `${categoryId ? 'hc_' : 'landingHub'}${analytics}`;
            const container_id = link?.container_id;
            const c_original_target = `${item.data?.permalink}${
              link &&
              !link?.onlyLink &&
              getParams({
                link,
                source,
                label,
                uuid: uuid(),
                webView,
                categoryId,
              })
            }`;

            eventData.c_original_target = c_original_target;
            eventData.c_id = `/splinter/${item.componentName.toLowerCase()}`;
            eventData.c_element_order = index + 1 || 'N/A';
            eventData.c_campaign = label;
            eventData.c_uid = uuid();

            if (sendContainersFlag) {
              eventData.c_container_id = container_id ?? 'not_apply';
            }

            const meliTrack = {};
            melidata('createEventTrack', meliTrack);
            melidata('withPath', '/splinter/component', meliTrack);
            melidata('withData', eventData, meliTrack);
            melidata('sendTrack', meliTrack, 'component_prints');
          } else {
            const isCarousel = component_name === 'Carousel';
            const isMerchEngineMS = component_name === 'MainSliderPlacement';
            const link = item.data?.link;
            const label = item.data?.label;
            const source = `landingHub${analytics}`;
            const container_id = link?.container_id;

            const c_original_target = `${link?.url || link}${
              link && !link?.onlyLink ? getParams({ link, source, label, uuid: uuid(), webView }) : ''
            }`;
            const c_id = `/splinter/${item.component_name}${
              item.variations !== 'normal' ? `-${!isCarousel ? item.variations : variations}` : ''
            }`;

            if (sendContainersFlag) {
              eventData.c_container_id = container_id ?? 'not_apply';
            }

            eventData.c_original_target = c_original_target;
            eventData.c_id = c_id.toLowerCase();
            eventData.c_element_order = !isCarousel ? item.data.element_order : index + 1 || 'N/A';
            eventData.c_campaign = label;
            eventData.c_uid = uuid();
            if (isMerchEngineMS) {
              eventData = {
                ...eventData,
                ...item.data.event_data,
              };
            }

            const meliTrack = {};
            melidata('createEventTrack', meliTrack);
            melidata('withPath', '/splinter/component', meliTrack);
            melidata('withData', eventData, meliTrack);
            melidata('sendTrack', meliTrack, 'component_prints');
          }
        });
      }

      if (!componentItems.includes(component_name) && !excludeComponents.includes(component_name)) {
        const link = data?.link;
        const container_id = link?.container_id;
        const label = data?.label;
        const source = `landingHub${analytics}`;
        const c_original_target = `${link?.url}${
          link && !link?.onlyLink ? getParams({ link, source, label, uuid: uuid(), webView }) : ''
        }`;

        eventData.c_original_target = c_original_target;
        eventData.c_id = `/splinter/${component_name.toLowerCase()}${
          variations !== 'normal' ? `-${variations.toLowerCase()}` : ''
        }`;

        if (sendContainersFlag) {
          eventData.c_container_id = container_id ?? 'not_apply';
        }

        eventData.c_element_order = data.element_order || 'N/A';
        eventData.c_campaign = label;
        eventData.c_uid = uuid();
        const meliTrack = {};
        melidata('createEventTrack', meliTrack);
        melidata('withPath', '/splinter/component', meliTrack);
        melidata('withData', eventData, meliTrack);
        melidata('sendTrack', meliTrack, 'component_prints');
      }
    }
  }
}

module.exports = ComponentPrints;
